export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'periodStart',
                    type: 'after',
                    label: 'invoices.period_start',
                    inputType: 'date',
                    listener: 'periodStart',
                },
                {
                    key: 'periodEnd',
                    type: 'before',
                    label: 'invoices.period_end',
                    inputType: 'date',
                    listener: 'periodEnd',
                },
                {
                    key: '_search',
                    label: `${this.$t('invoices.invoice_number')}, ${this.$t(
                        'invoices.company'
                    ).toLowerCase()}, ${this.$t(
                        'invoices.restaurant'
                    ).toLowerCase()}`,
                    inputType: 'search',
                    quick: 'true',
                },
            ]
        },
    },
}
