<template>
    <div>
        <page-title>{{ $t('menu.invoices') }}</page-title>
        <invoices-table>
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.INVOICES_CREATE"
                ></create-button>
            </template>
        </invoices-table>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import InvoicesTable from '@/components/pages/invoices/Table'
import AuthMixin from '@/components/mixins/AuthMixin'
import CreateButton from '@/components/form/Actions/CreateButton'

export default {
    components: {
        PageTitle,
        InvoicesTable,
        CreateButton,
    },
    mixins: [AuthMixin],
    data() {
        return {
            routeType,
            companyFilter: null,
        }
    },
}
</script>
