<template>
    <table-container>
        <data-iterator ref="iterator" :api="api" :filters.sync="filters">
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    :hide-search-box="true"
                ></table-filters>
            </template>

            <template v-if="isSuperAdmin" v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <template v-slot:[`item.deliveryUid`]="{ item }">
                        <template v-if="item.deliveryUid">{{
                            item.deliveryUid
                        }}</template>
                        <template v-else>-</template>
                    </template>

                    <template v-slot:[`item.periodStart`]="{ item }">
                        {{
                            $date(item.periodStart.substr(0, 10))
                                .locale(locale)
                                .format('L')
                        }}
                    </template>

                    <template v-slot:[`item.periodEnd`]="{ item }">
                        {{
                            $date(item.periodEnd.substr(0, 10))
                                .locale(locale)
                                .format('L')
                        }}
                    </template>

                    <template v-slot:[`item.company`]="{ item }">
                        <template v-if="item.company">{{
                            item.company.name
                        }}</template>
                        <template v-else>-</template>
                    </template>

                    <template v-slot:[`item.restaurant`]="{ item }">
                        <template v-if="item.restaurant">{{
                            item.restaurant.name
                        }}</template>
                        <template v-else>-</template>
                    </template>

                    <template v-slot:[`item.amount`]="{ item }">
                        {{ item.amount | currency(currencyCode) }}
                    </template>

                    <template v-slot:[`item.amountDue`]="{ item }">
                        {{ item.amountDue | currency(currencyCode) }}
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <table-button
                            v-if="!isSuperAdmin"
                            :tooltip="$t('invoices.download')"
                            color="blue-grey"
                            icon="file_download"
                            approve-color="error"
                            @execute="
                                ;(selectedFile = item),
                                    (fileDownloadDialog = true)
                            "
                        ></table-button>
                        <edit-button
                            v-if="isSuperAdmin"
                            :to="getEditLink(item)"
                        ></edit-button>
                        <delete-action
                            v-if="isSuperAdmin"
                            :entity-name="item.invoiceNumber"
                            :entity-id="item.id"
                            :api="api"
                            strong
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
                <confirm-modal
                    v-if="isSuperAdmin"
                    v-model="dialog"
                    :title="$t('texts.are_you_sure')"
                    :text="
                        $t('texts.you_will_delete_this_item', {
                            item: `${selectedInvoiceNumber}`,
                        })
                    "
                    approve-color="red"
                    :approve-text="$t('actions.delete')"
                    @onApprove="deleteFile(selectedFile.id)"
                    @onCancel="selectedFile = null"
                ></confirm-modal>
                <invoice-download-modal
                    v-if="!isSuperAdmin"
                    v-model="fileDownloadDialog"
                    :item="selectedFile || null"
                    @onApprove="deleteFile(selectedFile.id)"
                    @onCancel="selectedFile = null"
                ></invoice-download-modal>
            </template>
            <!-- batch actions -->
            <template v-if="isSuperAdmin" v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    strong
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import AuthMixin from '@/components/mixins/AuthMixin'
import configType from '@/store/type/configType'
import TableButton from '@/components/table/buttons/TableButton'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import InvoiceDownloadModal from '@/components/confirm/InvoiceDownloadModal'
import Headers from '@/pages/invoices/mixins/Headers'
import ListFilters from '@/pages/invoices/mixins/ListFilters'

import { mapGetters } from 'vuex'

export default {
    components: {
        TableButton,
        ConfirmModal,
        InvoiceDownloadModal,
    },
    mixins: [
        DataIteratorMixin,
        DataTableMixin,
        Headers,
        ListFilters,
        AuthMixin,
    ],
    data() {
        return {
            api: RestApiType.INVOICES,
            dialog: false,
            fileDownloadDialog: false,
            selectedFile: null,
            filters: {
                order: {
                    periodStart: 'desc',
                },
            },
        }
    },
    computed: {
        ...mapGetters({
            currentLocale: configType.getters.CURRENT_LOCALE,
            currencyCode: configType.getters.CURRENCY_CODE,
        }),
        tableName() {
            return this.$t('menu.invoices')
        },
        locale() {
            return this.currentLocale == 'en' ? 'en-gb' : this.currentLocale
        },
        selectedInvoiceNumber: {
            get() {
                return this.selectedFile?.invoiceNumber || null
            },
            set(val) {
                this.selectedFile.invoiceNumber = val
            },
        },
    },
    methods: {
        getEditLink(item) {
            return {
                name: routeType.INVOICES_EDIT,
                params: { entityId: item.id },
            }
        },
        downloadFilesModal(itemId) {
            return itemId
        },
    },
}
</script>
