<template>
    <modal-dialog
        v-model="dialog"
        :title="
            title
                ? title
                : $t('invoices.invoice_files_title', {
                      entityName: InvoiceNumberTitle,
                  })
        "
        cancel-button
        :cancel-text="cancelText"
        :ok-button="false"
        :show-overlay="processing"
        max-width="800"
        @cancel="onCancel"
        @ok="onApprove"
    >
        <inline-files-table
            v-if="item"
            ref="inlinefiles"
            :billing-folder-id="item.billingFolderId"
            :type="isCompanyAdmin ? 'company' : 'restaurant'"
            :relation-id="myEntityId"
        />
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/dialog/ModalDialog'
import { mapGetters } from 'vuex'
import AuthMixin from '@/components/mixins/AuthMixin'
import IdFromIri from '@/services/IdFromIri'
import InlineFilesTable from '@/components/pages/invoices/InlineFilesTable.vue'
import authType from '@/store/type/authType'

export default {
    components: {
        ModalDialog,
        InlineFilesTable,
    },
    mixins: [AuthMixin],
    props: {
        item: {
            type: Object,
        },
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        approveText: {
            type: String,
            default: null,
        },
        approveColor: {
            type: String,
            default: null,
        },
        cancelText: {
            type: String,
            default: null,
        },
        processing: {
            type: Boolean,
        },
    },
    data() {
        return {
            dialog: false,
        }
    },
    computed: {
        ...mapGetters({
            activeCompanyId: authType.getters.ACTIVE_COMPANY_ID,
        }),
        myEntityId() {
            return this.activeCompanyId
                ? IdFromIri.getId(this.item.company['@id'])
                : IdFromIri.getId(this.item.restaurant['@id'])
        },
        InvoiceNumberTitle() {
            return this.item?.invoiceNumber ? this.item.invoiceNumber : null
        },
    },
    watch: {
        value() {
            this.dialog = this.value
        },
    },
    methods: {
        onApprove() {
            this.$emit('input', false)
            this.$emit('onApprove')
        },
        onCancel() {
            this.$emit('input', false)
            this.$emit('onCancel')
        },
    },
}
</script>
