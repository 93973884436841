import AuthMixin from '@/components/mixins/AuthMixin'

export default {
    mixins: [AuthMixin],
    computed: {
        headers() {
            const headers = [
                {
                    value: 'periodStart',
                    text: this.$t('invoices.period_start'),
                    type: 'string',
                },
                {
                    value: 'periodEnd',
                    text: this.$t('invoices.period_end'),
                    type: 'string',
                },
            ]

            if (this.isSuperAdmin) {
                headers.push(
                    {
                        value: 'company.name',
                        text: this.$t('invoices.company'),
                        type: 'string',
                    },
                    {
                        value: 'restaurant.name',
                        text: this.$t('invoices.restaurant'),
                        type: 'string',
                    }
                )
            }

            if (this.isCompanyAdmin) {
                headers.push({
                    value: 'company.name',
                    text: this.$t('invoices.company'),
                    type: 'string',
                })
            }

            if (this.isRestaurantAdmin) {
                headers.push({
                    value: 'restaurant.name',
                    text: this.$t('invoices.restaurant'),
                    type: 'string',
                })
            }

            headers.push(
                {
                    value: 'invoiceNumber',
                    text: this.$t('invoices.invoice_number'),
                    type: 'string',
                },
                {
                    value: 'amount',
                    text: this.$t('invoices.amount'),
                    type: 'string',
                    width: '110',
                    align: 'right',
                }
            )

            return headers
        },
    },
}
